import * as React from "react";
import { Banner } from "@emisgroup/ui-banner";
import { useTranslation } from "@emisgroup/application-intl";
import CheckCircle from "~icons/ic/baseline-check-circle-outline";
import InfoError from "~icons/ic/outline-do-not-disturb-on";
import Warning from "~icons/ic/baseline-warning-amber";
import { CareProviderRecord } from "../types";
import { RedirectsContext, ReferralOutcome } from "../../context/redirects";
import { TelemetryContext } from "../../telemetry";
import "./submissionResult.scss";

const BLEEPA_SEND_MODE = "Bleepa";

export type ReferralResult = {
    declined: boolean;
    notificationMessage: string;
    succeeded: boolean;
};

type AddressProps = {
    address: {
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        addressCity: string;
        addressCountry: string;
        postalCode: string;
    };
};

function Address({
    address: { addressLine1, addressLine2, addressLine3, addressCity, addressCountry, postalCode },
}: AddressProps) {
    return (
        <div>
            {[addressLine1, addressLine2, addressLine3, addressCity, addressCountry, postalCode]
                .filter(Boolean)
                .join(", ")}
        </div>
    );
}

type BranchDetailsProps = {
    careProvider: CareProviderRecord;
};

function BranchDetails({ careProvider: { name, address, email, telephone } }: BranchDetailsProps) {
    const { t } = useTranslation();
    return (
        <div className="branch-details">
            <div>{t("Referral.SubmitSuccess.BranchName")}</div>
            <div>{name}</div>
            <div>{t("Referral.SubmitSuccess.Address")}</div>
            <Address address={address} />
            <div>{t("Referral.SubmitSuccess.TelephoneNumber")}</div>
            <div>{telephone ?? ""}</div>
            <div>{t("Referral.SubmitSuccess.EmailAddress")}</div>
            <div>{email ? <a href={`mailto:${email}`}>{email}</a> : ""}</div>
        </div>
    );
}

type PracticeLedSuccessProps = {
    careProvider: CareProviderRecord;
    sendMode?: string;
};
const PracticeLedSuccess = ({ careProvider, sendMode }: PracticeLedSuccessProps) => {
    const { t } = useTranslation();

    if (sendMode === BLEEPA_SEND_MODE)
        return (
            <div className="bleepa-confirmation">
                <p>{t("Referral.SubmitSuccess.Bleepa.ReferralWillBeTriaged")}</p>
                <p>{t("Referral.SubmitSuccess.Bleepa.PatientWillBeContactedDirectly")}</p>
                <p>{t("Referral.SubmitSuccess.Bleepa.PracticeWillBeInformedOfOutcome")}</p>
            </div>
        );

    return (
        <>
            <div className="ask-patient-to">{t("Referral.SubmitSuccess.AskPatientTo")}</div>
            <div className="practice-led-options">
                <ol>
                    <li>{t("Referral.SubmitSuccess.ContactThePharmacy")}</li>
                    <li>{t("Referral.SubmitSuccess.WaitForCall")}</li>
                </ol>
            </div>
            <BranchDetails careProvider={careProvider} />
            <div className="email-confirmation">{t("Referral.SubmitSuccess.EmailConfirmation")}</div>
        </>
    );
};

const PatientLedSuccess = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="awaiting-confirmation">
                <p>{t("Referral.SubmitSuccess.AwaitingConfirmation")}</p>
                <p>{t("Referral.SubmitSuccess.GetBackShortly")}</p>
                <p>{t("Referral.SubmitSuccess.AssessmentDetailsInEmail")}</p>
            </div>
            <div className="email-check">
                <span className="received">{t("Referral.SubmitSuccess.EmailNotReceived")}</span>
                <span>{t("Referral.SubmitSuccess.SpamCheck")}</span>
            </div>
        </>
    );
};

type RedirectFromSucceededProps = {
    isPatientLed: boolean;
};

const RedirectFromSucceeded = ({ isPatientLed }: RedirectFromSucceededProps) => {
    const { t } = useTranslation();
    const { hasCustomRedirect, referralRedirect, redirectDelaySeconds } = React.useContext(RedirectsContext);
    const isCustomRedirectDefined = hasCustomRedirect(ReferralOutcome.Referred);
    const [secondsRemaining, setSecondsRemaining] = React.useState<number>(redirectDelaySeconds);

    const handleRedirect = React.useCallback(() => {
        setSecondsRemaining(0);
        referralRedirect(ReferralOutcome.Referred);
    }, [setSecondsRemaining, referralRedirect]);

    const handleRedirectClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.currentTarget.blur();
            handleRedirect();
        },
        [handleRedirect],
    );

    React.useEffect(() => {
        if (!isCustomRedirectDefined || secondsRemaining === 0) return;
        const secondsRemainingInterval = setTimeout(() => {
            if (secondsRemaining === 1) {
                handleRedirect();
            } else {
                setSecondsRemaining(seconds => seconds - 1);
            }
        }, 1000);

        return () => clearTimeout(secondsRemainingInterval);
    });

    return (
        <>
            {isCustomRedirectDefined && (
                <>
                    <p className="before-you-arrive">
                        {t(
                            isPatientLed
                                ? "Referral.SubmitSuccess.BeforeYouArriveForAppointment"
                                : "Referral.SubmitSuccess.BeforeThePatientArrivesForAppointment",
                        )}
                    </p>
                    <div className="redirect-container">
                        <button
                            data-testid="redirect-button"
                            className="nhsuk-button"
                            type="button"
                            onClick={handleRedirectClick}
                        >
                            {t("Referral.SubmitSuccess.AdditionalInstructions")}
                        </button>

                        {secondsRemaining > 0 && (
                            <div className="nhsuk-u-margin-bottom-5 nhsuk-u-margin-left-3">
                                <span>{t("Referral.SubmitSuccess.YouWillBeRedirectedIn")} </span>
                                <span>
                                    {secondsRemaining} {t("Referral.SubmitSuccess.Seconds")}
                                </span>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

type SubmissionResultProps = {
    referralResult: ReferralResult;
    serviceName: string;
    careProvider: CareProviderRecord;
    referralErrorMessage?: string;
    isPatientLed: boolean;
    sendMode?: string;
    filingDisabled: boolean;
};

function SubmissionResult({
    referralResult,
    serviceName,
    careProvider,
    referralErrorMessage,
    isPatientLed,
    sendMode,
    filingDisabled,
}: SubmissionResultProps) {
    const { t } = useTranslation();
    const message = t(referralResult.notificationMessage);
    const succeeded = referralResult.succeeded;
    const { flushLogs } = React.useContext(TelemetryContext);

    React.useEffect(() => {
        flushLogs();
    }, []);

    const resultDetail = () => {
        return (
            <>
                <div className="title">
                    {succeeded &&
                        t(
                            isPatientLed
                                ? "Referral.SubmitSuccess.RequestReceived"
                                : "Referral.SubmitSuccess.ReferredTo",
                            {
                                serviceName,
                            },
                        )}
                    {!succeeded && t("Referral.SubmitFailure.CouldNotCreate")}
                </div>
                {succeeded && isPatientLed && PatientLedSuccess()}
                {succeeded && !isPatientLed && PracticeLedSuccess({ careProvider, sendMode })}
                {succeeded && RedirectFromSucceeded({ isPatientLed })}
                {!succeeded && referralErrorMessage && <div className="error-message">{referralErrorMessage}</div>}
            </>
        );
    };

    const declinedResultDetail = () => {
        return succeeded ? null : (
            <div className="record-not-updated">{t("Referral.SubmitFailure.RecordNotUpdated")}</div>
        );
    };

    const showDisabledFilingBanner = !isPatientLed && filingDisabled && succeeded;

    return (
        <div role="main" className="submission-result" data-testid="submission-result">
            {showDisabledFilingBanner && (
                <Banner className="disabled-filing-banner" color="warning">
                    <Banner.Cap>
                        <Warning title="" />
                    </Banner.Cap>
                    <Banner.Content>
                        <div>
                            <p>{t("Referral.FilingDisabled.FilingDisabledDueToTechnicalIssue")}</p>
                            <p>{t("Referral.FilingDisabled.NoEffectOnReferrals")}</p>
                            <p>{t("Referral.FilingDisabled.AddManuallyIfRequired")}</p>
                        </div>
                    </Banner.Content>
                </Banner>
            )}
            <Banner color={succeeded ? "confirm" : "error"} className="info-banner">
                <Banner.Cap>{succeeded ? <CheckCircle title="" /> : <InfoError title="" />}</Banner.Cap>
                <Banner.Content>{message}</Banner.Content>
            </Banner>
            {referralResult.declined ? declinedResultDetail() : resultDetail()}
        </div>
    );
}

export default SubmissionResult;
